<script>
import postsService from '@/services/posts.service'

export default {
  name: 'PostCategories',
	data() {
		return {
      items: [],
      headerFields: [
        "__slot:checkboxes",
        {
          name: "photo",
          label: this.$tc('photo'),
          customElement: "photo",
        },
        {
          name: "title",
          label: this.$t('title'),
          customElement: "title",
        },
        {
          name: "post_count",
          label: this.$tc('post', 2),
        },
        "__slot:actions:edit",
        "__slot:actions:delete",
      ],
		}
  },
	mounted() {
    this._get()
	},
	methods: {
    _get() {
      postsService.getAllCategories()
        .then(response => {
          this.items = response.data
        })
    },
    remove(id) {
      postsService.removeCategory({ id })
        .then(success => {
          this.$notify({
              message: this.$t('m.itemSuccessfullyDeleted', { item: this.$tc('postCategory') }),
              icon: "far fa-bell",
              horizontalAlign: 'right',
              verticalAlign: 'top',
              type: 'success',
            })
          const index = this.items.findIndex(obj => obj.id === id)
          this.items.splice(index, 1)
        })
    }
	},
}
</script>

<template>
  <div class="card">
    <div class="card-header">
      <div class="row">
        <div class="form-group col-12 text-right">
          <router-link :to="{ name: 'singlePostCategory', params: { id: 'new' } }">
            <BaseButton 
              type="green"
              gradient
            >
              {{ $t('addNewItem', { item: $tc('category') }) }}
            </BaseButton>
          </router-link>
        </div>
      </div>
    </div>
    <div class="card-body">
      <BaseListTable
        :items="items"
        :header-fields="headerFields"
        type="post-categories"
        @delete="remove"
      />
    </div>
  </div>
</template>

<style lang="sass" scoped>
@import '~@/assets/sass/design.sass'

</style>
